/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

h1, ion-title{
  font-weight:300;
  margin-top: 10px;
  text-transform: uppercase;
  color: var(--ion-color-step-800);
  font-size: 24px;
}

@media screen and (max-width: 380px) {
  ion-title{
    font-size:16px;
  }
}

.list2{
  font-size: 12px;
  color: var(--ion-color-step-800);
}
ion-label{
  --color: var(--ion-color-step-500);
}
.actionchip{
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
}

.primarychip{
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  ion-icon{
    --background: white;
  }

}

ion-searchbar {
  --color:var(--ion-color-primary);  
  --background: var(--ion-item-background);
  padding:8px 0px;
}

ion-toolbar{
  --background: var(--ion-background-color);
  
}

ion-backdrop{
  --backdrop-opacity: 0.8;
}
ion-content{
  --padding-top: 0px;
}

ion-item{
  --padding-bottom: 12px;
  --padding-top: 6px;
}

fa-icon{
  font-size: 26px;
}


/* Login Dialog */
.logout{
  margin-top: 35px; 
  margin-bottom: 35px;
  color: var(--ion-color-step-350);
}

.avatar, .usericon-mainmenu{
  margin: auto;
  color: var(--ion-color-step-350);
  font-size:70px;
  margin-bottom:15px;
}

.forgotPassword {
    text-align: center;
    margin: -12px 0 15px 0;
    float: left;
    width: 100%;
  }
  
  .forgotPassword span {
    color: var(--ion-color-primary);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
    padding-top: 20px;
  }

  .redirectToLogin {
    cursor: pointer;
    padding: 15px 0 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    display: block;
    color: var(--ion-color-primary-contrast);
  }
  
  .redirectToLogin .redirect {
    cursor: pointer;
    color: #ffffff;
    text-decoration: underline;
  }
  

.or {
    text-align: center;
    display: block;
    color: var(--ion-color-medium);
    background: var(--ion-background-color);
    position: relative;
    margin: 5px 0 0px;
  }
  
  .orInner {
    background: var(--ion-background-color);
    display: inline-block;
    z-index: 4;
    position: relative;
    padding: 0 12px;
  }
  
  .or:before {
    position: absolute;
    content: '';
    left: 0;
    top: 11px;
    width: 100%;
    height: 1px;
    background: var(--ion-color-medium);
  }

.icon-bewilligungspflichtig{
  margin:0px; 
  padding:0px; 
  color: var(--ion-color-warning);
  
  font-size:12px;  
  
  
}

.infobox{
  //background-color: var(--ion-color-warning);
  border:2px solid var(--ion-color-step-950);
  color: var(--ion-color-step-950);
  border-radius: 5px;
}
.infoicon{

}

.my-custom-class .alert-wrapper{
      --max-width: 400px;
}

.header-logo-element {
  background: url("/assets/parkbewilligung_logo2.svg") no-repeat;
  position: fixed;
  right:10px;
  width:180px;
  height: 36px;
  top: 10px;

}
  
@media (prefers-color-scheme: dark) {

  .header-logo-element {
      background: url("/assets/parkbewilligung_logo2_dark.svg") no-repeat;
      position: fixed;
      right:10px;
      width:180px;
      height: 36px;
      top: 10px;
  
  }

}

@media (min-width: 992px) {
  .fullscreen-modal {
    --height: 80vh;
    --width: 80%;

    ion-header{
      text-align: center;
    }
    .with-padding{
      margin-left:25% !important;
      margin-right:25% !important;
    }
  }
}

.leaflet-popup-content{
  min-width:150px !important;
}
